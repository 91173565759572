"use strict";

var _interopRequireWildcard = require("/Users/chuongdev/Desktop/project/180wtmvnd_admintool/node_modules/@babel/runtime/helpers/interopRequireWildcard").default;

var _interopRequireDefault = require("/Users/chuongdev/Desktop/project/180wtmvnd_admintool/node_modules/@babel/runtime/helpers/interopRequireDefault").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("/Users/chuongdev/Desktop/project/180wtmvnd_admintool/node_modules/@babel/runtime/helpers/objectSpread2"));

require("core-js/modules/es.object.to-string.js");

var _date = require("@/utils/date");

var enumConst = _interopRequireWildcard(require("@/utils/enum"));

var customer = _interopRequireWildcard(require("@/api/customer"));

var _moment = _interopRequireDefault(require("moment"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  props: {
    dialogDisabled: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      dialogWidth: "500px",
      tabName: "info",
      enumSex: {
        MALE: "Nam",
        FEMALE: "Nữ"
      },
      typeVehicles: [],
      dialogLoading: false,
      tempData: {
        type: "",
        openTime: "",
        closeTime: "",
        lat: 0,
        long: 0
      },
      dialogStatus: "",
      dialogVisible: false,
      loadingButton: false,
      rules: {
        name: {
          required: true,
          message: "Vui lòng nhập tên",
          trigger: "submit"
        },
        phone: {
          required: true,
          trigger: "submit",
          message: "Vui lòng nhập số điện thoại"
        }
      }
    };
  },
  watch: {
    tabName: function tabName(val) {
      switch (val) {
        case "info":
          this.dialogWidth = "500px";
          break;

        case "Affiliate":
          this.dialogWidth = "800px";
          break;

        default:
          this.dialogWidth = "1300px";
          break;
      }
    }
  },
  methods: {
    editData: function editData() {
      var _this = this;

      this.$refs["dataForm"].validate(function (valid) {
        if (valid) {
          var data = {
            customer: _this.tempData
          };
          _this.loadingButton = true;
          customer.update(_this.tempData.id, data).then(function (res) {
            if (res.status) {
              _this.$notify({
                type: "success",
                message: "Cập nhật thành công"
              });

              _this.dialogVisible = false;

              _this.$emit("update:success");
            }
          }).finally(function () {
            _this.loadingButton = false;
          });
        }
      });
    },
    handleEdit: function handleEdit(data) {
      var _this2 = this;

      this.dialogStatus = "edit";
      this.dialogVisible = true;
      this.$nextTick(function () {
        _this2.$refs["dataForm"].clearValidate();

        _this2.tempData = (0, _objectSpread2.default)({}, data);
      });
    },
    handleAdd: function handleAdd() {
      var _this3 = this;

      this.dialogVisible = true;
      this.dialogStatus = "add";
      this.tempData = {};
      this.$nextTick(function () {
        _this3.$refs["dataForm"].clearValidate();
      });
    },
    createData: function createData() {
      var _this4 = this;

      this.$refs["dataForm"].validate(function (valid) {
        if (valid) {
          var data = {
            customer: _this4.tempData
          };
          _this4.loadingButton = true;
          customer.create(data).then(function (res) {
            if (res.status) {
              _this4.$notify({
                type: "success",
                message: "Tạo thành công"
              });

              _this4.dialogVisible = false;

              _this4.$refs["dataForm"].clearValidate();

              _this4.$emit("create:success");
            }
          }).finally(function () {
            _this4.loadingButton = false;
          });
        }
      });
    },
    formatDateTime: function formatDateTime(timestamp) {
      return (0, _date.formatDateTime)(timestamp);
    }
  }
};
exports.default = _default;