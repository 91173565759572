var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("div", { staticClass: "filter-container" }, [
        _c(
          "div",
          { staticClass: "filter-item" },
          [
            _c("el-input", {
              attrs: { placeholder: "Tìm theo tên hoặc sđt" },
              nativeOn: {
                keydown: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return _vm.fetchData($event)
                },
              },
              model: {
                value: _vm.listQuery.search,
                callback: function ($$v) {
                  _vm.$set(_vm.listQuery, "search", $$v)
                },
                expression: "listQuery.search",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "filter-item" },
          [
            _c(
              "el-select",
              {
                attrs: { placeholder: "Chọn trạng thái" },
                model: {
                  value: _vm.listQuery.status,
                  callback: function ($$v) {
                    _vm.$set(_vm.listQuery, "status", $$v)
                  },
                  expression: "listQuery.status",
                },
              },
              [
                _c("el-option", {
                  attrs: { label: "Tất cả trạng thái", value: null },
                }),
                _vm._l(_vm.TurnStatus, function (item, key) {
                  return _c("el-option", {
                    key: key,
                    attrs: { label: item, value: key },
                  })
                }),
              ],
              2
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "filter-item" },
          [
            _c(
              "el-button",
              {
                attrs: { type: "primary", size: "small" },
                on: { click: _vm.fetchData },
              },
              [_vm._v("Tìm")]
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "filter-item" },
          [
            _c(
              "el-button",
              {
                attrs: {
                  type: "primary",
                  icon: "el-icon-download",
                  loading: _vm.loadingExport,
                },
                on: { click: _vm.handleExport },
              },
              [_vm._v("Export excel")]
            ),
          ],
          1
        ),
      ]),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: { data: _vm.data, border: "", stripe: "" },
        },
        [
          _c("el-table-column", {
            attrs: { label: "Khách hàng", prop: "customer.name" },
          }),
          _c("el-table-column", {
            attrs: { label: "SĐT", prop: "customer.phone" },
          }),
          _c("el-table-column", {
            attrs: { label: "Phần thưởng", prop: "piece.name" },
          }),
          _c("el-table-column", {
            attrs: { label: "Trạng thái" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    !row.isEdit
                      ? [
                          _c("span", [
                            _vm._v(_vm._s(_vm.TurnStatus[row.status])),
                          ]),
                          _c(
                            "el-tooltip",
                            {
                              attrs: { content: "Cập nhật", placement: "top" },
                            },
                            [
                              _c("el-button", {
                                attrs: {
                                  type: "primary",
                                  icon: "el-icon-edit",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleChangeStatus(row)
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      : [
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "" },
                              model: {
                                value: row.tmpStatus,
                                callback: function ($$v) {
                                  _vm.$set(row, "tmpStatus", $$v)
                                },
                                expression: "row.tmpStatus",
                              },
                            },
                            _vm._l(_vm.TurnStatus, function (item, key) {
                              return _c("el-option", {
                                key: key,
                                attrs: { label: item, value: key },
                              })
                            }),
                            1
                          ),
                          _c(
                            "el-tooltip",
                            { attrs: { content: "Lưu", placement: "top" } },
                            [
                              _c("el-button", {
                                attrs: {
                                  type: "primary",
                                  icon: "el-icon-s-promotion",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.changeStatus(row)
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-tooltip",
                            { attrs: { content: "Huỷ", placement: "top" } },
                            [
                              _c("el-button", {
                                attrs: {
                                  type: "danger",
                                  icon: "el-icon-circle-close",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleCancel(row)
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("pagination", {
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.limit,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.listQuery, "limit", $event)
          },
          pagination: _vm.fetchData,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }