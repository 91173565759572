"use strict";

var _interopRequireWildcard = require("/Users/chuongdev/Desktop/project/180wtmvnd_admintool/node_modules/@babel/runtime/helpers/interopRequireWildcard").default;

var _interopRequireDefault = require("/Users/chuongdev/Desktop/project/180wtmvnd_admintool/node_modules/@babel/runtime/helpers/interopRequireDefault").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es.object.to-string.js");

require("core-js/modules/web.dom-collections.for-each.js");

require("core-js/modules/es.function.name.js");

var _date = require("@/utils/date");

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

var customer = _interopRequireWildcard(require("@/api/customer"));

var _CustomerDialog = _interopRequireDefault(require("./components/CustomerDialog"));

var _format = require("@/utils/format");

var _Export2Excel = require("@/vendor/Export2Excel");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Pagination: _Pagination.default,
    CustomerDialog: _CustomerDialog.default
  },
  data: function data() {
    return {
      listLoading: false,
      total: 0,
      baseUrl: process.env.VUE_APP_BASE_API,
      baseUrlMedia: process.env.VUE_APP_BASE_URL_MEDIA,
      enumSex: {
        MALE: "Nam",
        FEMALE: "Nữ"
      },
      listQuery: {
        page: 1,
        limit: 10,
        search: "",
        isBlock: null
      },
      tableData: [],
      loadingExport: false
    };
  },
  created: function created() {
    this.getTableData();
  },
  methods: {
    handleExport: function handleExport() {
      var _this = this;

      this.loadingExport = true;
      customer.findAll().then(function (res) {
        var header = ["Họ tên", "SĐT", "Ngày tạo"];
        var data = [];
        res.data.customers.forEach(function (element) {
          data.push([element.name, element.phone, _this.$formatDateTime(element.createdAt)]);
        });
        (0, _Export2Excel.export_json_to_excel)({
          header: header,
          data: data,
          filename: "Data khách hàng"
        });
      }).finally(function () {
        return _this.loadingExport = false;
      });
    },
    formatCurrency: function formatCurrency(n) {
      return (0, _format.formatCurrency)(n);
    },
    handleEdit: function handleEdit(row) {
      this.$refs["dialog"].handleEdit(row);
    },
    handleAdd: function handleAdd() {
      this.$refs["dialog"].handleAdd();
    },
    handleImgError: function handleImgError(event) {
      event.currentTarget.src = require("@/assets/images/avatar_default.svg");
    },
    handleUnBlock: function handleUnBlock(row) {
      var _this2 = this;

      this.$confirm("Khách hàng này sẽ được mở khóa, bạn có muốn tiếp tục?", "Cảnh báo", {
        confirmButtonText: "OK",
        cancelButtonText: "Đóng",
        type: "warning"
      }).then(function () {
        var data = {
          customer: {
            isBlock: false
          }
        };
        customer.update(row.id, data).then(function () {
          row.isBlock = false;

          _this2.$message({
            type: "success",
            message: "Mở khóa khách hàng thành công"
          });
        });
      });
    },
    handleBlock: function handleBlock(row) {
      var _this3 = this;

      this.$confirm("Khách hàng này sẽ bị khóa, bạn có muốn tiếp tục?", "Cảnh báo", {
        confirmButtonText: "OK",
        cancelButtonText: "Đóng",
        type: "warning"
      }).then(function () {
        var data = {
          customer: {
            isBlock: true
          }
        };
        customer.update(row.id, data).then(function () {
          row.isBlock = true;

          _this3.$message({
            type: "success",
            message: "Khóa khách hàng thành công"
          });
        });
      });
    },
    getTableData: function getTableData() {
      var _this4 = this;

      this.listLoading = true;
      customer.findAll(this.listQuery).then(function (res) {
        _this4.tableData = res.data.customers;
        _this4.total = res.data.total;
      }).finally(function () {
        _this4.listLoading = false;
      });
    },
    formatDateTime: function formatDateTime(timestamp) {
      return (0, _date.formatDateTime)(timestamp);
    }
  }
};
exports.default = _default;