"use strict";

var _interopRequireWildcard = require("/Users/chuongdev/Desktop/project/180wtmvnd_admintool/node_modules/@babel/runtime/helpers/interopRequireWildcard").default;

var _interopRequireDefault = require("/Users/chuongdev/Desktop/project/180wtmvnd_admintool/node_modules/@babel/runtime/helpers/interopRequireDefault").default;

require("core-js/modules/es.object.to-string.js");

require("core-js/modules/web.dom-collections.for-each.js");

require("core-js/modules/es.object.keys.js");

require("core-js/modules/es.array.filter.js");

require("/Users/chuongdev/Desktop/project/180wtmvnd_admintool/node_modules/core-js/modules/es.array.iterator.js");

require("/Users/chuongdev/Desktop/project/180wtmvnd_admintool/node_modules/core-js/modules/es.promise.js");

require("/Users/chuongdev/Desktop/project/180wtmvnd_admintool/node_modules/core-js/modules/es.object.assign.js");

require("/Users/chuongdev/Desktop/project/180wtmvnd_admintool/node_modules/core-js/modules/es.promise.finally.js");

var _vue = _interopRequireDefault(require("vue"));

require("normalize.css/normalize.css");

var _elementUi = _interopRequireDefault(require("element-ui"));

require("./styles/element-variables.scss");

require("@/styles/index.scss");

var _App = _interopRequireDefault(require("./App.vue"));

var _store = _interopRequireDefault(require("./store"));

var _router = _interopRequireDefault(require("./router"));

require("@/plugins/element");

require("@/plugins/input-mask");

require("./icons");

require("./permission");

require("./utils/error-log");

var _vueClipboard = _interopRequireDefault(require("vue-clipboard2"));

var _date = require("@/utils/date");

var _InputMoney = _interopRequireDefault(require("@/components/InputMoney/InputMoney.vue"));

var filters = _interopRequireWildcard(require("./filters"));

var _fontawesomeSvgCore = require("@fortawesome/fontawesome-svg-core");

var _freeSolidSvgIcons = require("@fortawesome/free-solid-svg-icons");

var _vueFontawesome = require("@fortawesome/vue-fontawesome");

// a modern alternative to CSS resets
// global css
//google map
// import  "@/plugins/google-maps";
//
//====IMPORT PLUGINS====
//====END - IMPORT PLUGINS====
// icon
// permission control
// error log
_vue.default.prototype.$formatDateTime = _date.formatDateTime;
_vue.default.prototype.$formatDate = _date.formatDate;

var VueInputMask = require("vue-inputmask").default;

_vue.default.use(VueInputMask);

_vue.default.component("InputMoney", _InputMoney.default);

_fontawesomeSvgCore.library.add(_freeSolidSvgIcons.faMotorcycle, _freeSolidSvgIcons.faTruck, _freeSolidSvgIcons.faUserTag, _freeSolidSvgIcons.faUtensils, _freeSolidSvgIcons.faPeopleCarry, _freeSolidSvgIcons.faUserSecret, _freeSolidSvgIcons.faDollarSign, _freeSolidSvgIcons.faTicketAlt, _freeSolidSvgIcons.faUserNurse, _freeSolidSvgIcons.faProcedures, _freeSolidSvgIcons.faAssistiveListeningSystems);

_vue.default.component("font-awesome-icon", _vueFontawesome.FontAwesomeIcon);

_vue.default.use(_elementUi.default, {
  size: "small"
});

_vue.default.use(_vueClipboard.default); // register global utility filters


Object.keys(filters).forEach(function (key) {
  // @ts-ignore 
  _vue.default.filter(key, filters[key]);
});
_vue.default.prototype.$devMode = process.env.VUE_APP_CONFIG_SYSTEM == "true";
_vue.default.prototype.$baseUrl = process.env.VUE_APP_BASE_API;
_vue.default.prototype.$baseUrlMedia = process.env.VUE_APP_BASE_URL_MEDIA;
_vue.default.config.productionTip = false;
new _vue.default({
  el: "#app",
  router: _router.default,
  store: _store.default,
  render: function render(h) {
    return h(_App.default);
  }
});