"use strict";

var _interopRequireDefault = require("/Users/chuongdev/Desktop/project/180wtmvnd_admintool/node_modules/@babel/runtime/helpers/interopRequireDefault").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("/Users/chuongdev/Desktop/project/180wtmvnd_admintool/node_modules/@babel/runtime/helpers/objectSpread2"));

var _vuex = require("vuex");

var _Logo = _interopRequireDefault(require("./Logo"));

var _SidebarItem = _interopRequireDefault(require("./SidebarItem"));

var _variables2 = _interopRequireDefault(require("@/styles/variables.scss"));

var _router = require("@/router");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    SidebarItem: _SidebarItem.default,
    Logo: _Logo.default
  },
  data: function data() {
    return {
      permission_routes: _router.constantRoutes
    };
  },
  computed: (0, _objectSpread2.default)((0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(["sidebar"])), {}, {
    activeMenu: function activeMenu() {
      var route = this.$route;
      var meta = route.meta,
          path = route.path; // if set path, the sidebar will highlight the path you set

      if (meta.activeMenu) {
        return meta.activeMenu;
      }

      return path;
    },
    showLogo: function showLogo() {
      return this.$store.state.settings.sidebarLogo;
    },
    variables: function variables() {
      return _variables2.default;
    },
    isCollapse: function isCollapse() {
      return !this.sidebar.opened;
    }
  }),
  mounted: function mounted() {}
};
exports.default = _default;