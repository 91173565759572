var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.status == "CREATE" ? "Thêm mới" : "Cập nhật",
        visible: _vm.visible,
        width: "500px",
        top: "30px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { model: _vm.form, rules: _vm.rules, "label-position": "top" },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "Tên", prop: "name" } },
            [
              _c("el-input", {
                attrs: { placeholder: "" },
                model: {
                  value: _vm.form.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "name", $$v)
                  },
                  expression: "form.name",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "Mô tả thêm", prop: "description" } },
            [
              _c("el-input", {
                attrs: { type: "textarea", placeholder: "" },
                model: {
                  value: _vm.form.description,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "description", $$v)
                  },
                  expression: "form.description",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "Tỷ lệ trúng(%)", prop: "ratio" } },
            [
              _c("input-money", {
                model: {
                  value: _vm.form.ratio,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "ratio", $$v)
                  },
                  expression: "form.ratio",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "Số lượng", prop: "total" } },
            [
              _c("input-money", {
                model: {
                  value: _vm.form.total,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "total", $$v)
                  },
                  expression: "form.total",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticClass: "text-center",
              attrs: { label: "thumbnail", prop: "thumbnail" },
            },
            [
              _c(
                "el-upload",
                {
                  attrs: {
                    name: "file",
                    drag: "",
                    "before-upload": _vm.beforeUpload,
                    action: _vm.$baseUrl + "/piece/upload",
                    "on-success": _vm.handleUploadSuccess,
                    "show-file-list": false,
                    headers: { token: _vm.token },
                  },
                },
                [
                  !_vm.form.thumbnail
                    ? [
                        _c("i", { staticClass: "el-icon-upload" }),
                        _c("div", { staticClass: "el-upload__text" }, [
                          _vm._v(" Kéo thả vào đây hoặc "),
                          _c("em", [_vm._v("click vào để upload banner")]),
                        ]),
                      ]
                    : _c("img", {
                        staticStyle: {
                          width: "100%",
                          height: "100%",
                          "object-fit": "contain",
                        },
                        attrs: {
                          src: _vm.$baseUrlMedia + _vm.form.thumbnail,
                          alt: "",
                        },
                      }),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.visible = false
                },
              },
            },
            [_vm._v("Đóng")]
          ),
          _c(
            "el-button",
            {
              attrs: { loading: _vm.loading, type: "primary" },
              on: {
                click: function ($event) {
                  _vm.status == "CREATE" ? _vm.createData() : _vm.updateData()
                },
              },
            },
            [_vm._v("Đồng ý")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }