"use strict";

var _interopRequireDefault = require("/Users/chuongdev/Desktop/project/180wtmvnd_admintool/node_modules/@babel/runtime/helpers/interopRequireDefault").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.tagOptions = exports.VehicleType = exports.TransactionType = exports.TYPE_CONTENT_DEFINE = exports.STORE_TYPE = exports.PARAMS = exports.OrderStatus = exports.ORDER_TYPE = exports.ORDER_TRANSPORT_STATUS = exports.ORDER_FOOD_STATUS = exports.ORDER_DELIVERY_STATUS = exports.NewsStoreType = exports.CustomerCategory = exports.CoverType = exports.CategoryFood = void 0;

var _defineProperty2 = _interopRequireDefault(require("/Users/chuongdev/Desktop/project/180wtmvnd_admintool/node_modules/@babel/runtime/helpers/defineProperty"));

var _tagOptions;

var STORE_TYPE = {
  FOOD: "Thức ăn",
  DRINK: "Thức uống"
};
exports.STORE_TYPE = STORE_TYPE;
var ORDER_FOOD_STATUS = {
  WAITING: "Đang đợi",
  DELIVERING: "Đang giao",
  COMPLETE: "Hoàn thành",
  CANCEL: "Huỷ",
  FIND_DRIVER: "Đang tìm tài xế",
  ACCEPT_ORDER: "Chấp nhận đơn",
  DRIVER_CANCEL: "Tài xế huỷ",
  CUSTOMER_CANCEL: "Khách huỷ",
  NOT_FOUND_DRIVER: "Không có tài xế"
};
exports.ORDER_FOOD_STATUS = ORDER_FOOD_STATUS;
var ORDER_DELIVERY_STATUS = {
  WAITING: "Đang đợi",
  DELIVERING: "Đang giao",
  COMPLETE: "Hoàn thành",
  CANCEL: "Huỷ",
  FIND_DRIVER: "Đang tìm tài xế",
  ACCEPT_ORDER: "Chấp nhận đơn",
  DRIVER_CANCEL: "Tài xế huỷ",
  CUSTOMER_CANCEL: "Khách huỷ",
  NOT_FOUND_DRIVER: "Không có tài xế"
};
exports.ORDER_DELIVERY_STATUS = ORDER_DELIVERY_STATUS;
var ORDER_TRANSPORT_STATUS = {
  WAITING: "Đang đợi",
  DELIVERING: "Đang giao",
  COMPLETE: "Hoàn thành",
  CANCEL: "Huỷ",
  FIND_DRIVER: "Đang tìm tài xế",
  ACCEPT_ORDER: "Chấp nhận đơn",
  DRIVER_CANCEL: "Tài xế huỷ",
  NOT_FOUND_DRIVER: "Không có tài xế",
  CUSTOMER_CANCEL: "Khách huỷ"
};
exports.ORDER_TRANSPORT_STATUS = ORDER_TRANSPORT_STATUS;
var TYPE_CONTENT_DEFINE = {
  ABOUT: "Thông tin chúng tôi",
  FAQ: "Câu hỏi thường gặp",
  HOW_TO_USE: "Cách sử dụng",
  SECURITY: "Bảo mật",
  TERM_CONDITION: "Điều khoản",
  HOW_TO_DEPOSIT: "Hướng dẫn nạp điểm",
  HOW_TO_USE_DRIVER: "Cách sử dụng (Tài xế)",
  FAQ_DRIVER: "Câu hỏi thường gặp (Tài xế)",
  BADGE_POINT: "Cách tính điểm"
};
exports.TYPE_CONTENT_DEFINE = TYPE_CONTENT_DEFINE;
var ORDER_TYPE = {
  FOOD: "Thức ăn",
  TRANSPORT: "Di chuyển xe máy",
  DELIVERY: "Giao hàng",
  TRANSPORT_CAR_7: "Di chuyển ô tô 7 chỗ",
  TRANSPORT_CAR_4: "Di chuyển ô tô 4 chỗ"
};
exports.ORDER_TYPE = ORDER_TYPE;
var PARAMS = {
  COMMISSION_FOOD: "Thức ăn",
  COMMISSION_DELIVERY: "Giao hàng",
  COMMISSION_TRANSPORT: "Di chuyển ",
  MIN_DRIVER_BALANCE: "Tài khoản tối thiểu",
  DISTANCE_FIND_DRIVER: "Khoảng cách tìm tài xế",
  RATE_POINT: "Tỷ lệ quy đổi",
  REWARD_RATE_STORE: "Thưởng đánh giá cửa hàng",
  REWARD_RATE_ORDER: "Thưởng đánh giá đơn hàng"
};
exports.PARAMS = PARAMS;
var tagOptions = (_tagOptions = {
  NOT_FOUND_DRIVER: {
    effect: "plain",
    type: "danger"
  },
  WAITING: {
    type: "warning",
    effect: "plain"
  },
  ACCEPT_ORDER: {
    type: "warning",
    effect: "plain"
  },
  DELIVERING: {
    type: "",
    effect: "plain"
  },
  DRIVER_CANCEL: {
    type: "danger",
    effect: "plain"
  },
  COMPLETE: {
    type: "success",
    effect: "dark"
  }
}, (0, _defineProperty2.default)(_tagOptions, "NOT_FOUND_DRIVER", {
  type: "danger",
  effect: "plain"
}), (0, _defineProperty2.default)(_tagOptions, "CUSTOMER_CANCEL", {
  type: "danger",
  effect: "dark"
}), (0, _defineProperty2.default)(_tagOptions, "FIND_DRIVER", {
  type: "danger",
  effect: "dark"
}), (0, _defineProperty2.default)(_tagOptions, "CANCEL", {
  type: "danger",
  effect: "dark"
}), _tagOptions);
exports.tagOptions = tagOptions;
var VehicleType = {
  BIKE: "Môtô 2 bánh",
  CAR_4: "Ôtô 4 bánh",
  CAR_7: "Ôtô 7 bánh"
};
exports.VehicleType = VehicleType;
var NewsStoreType = {
  Plus: "PLUS",
  Coffee: "COFFEE"
};
exports.NewsStoreType = NewsStoreType;
var TransactionType = {
  Deposit: "DEPOSIT",
  Withdraw: "WITHDRAW",
  Income: "INCOME"
};
exports.TransactionType = TransactionType;
var CategoryFood = {
  VEGETARIAN: "Món chay",
  SNACK: "Ăn vặt",
  RICE: "Cơm",
  MILK_TEA: "Trà sữa"
};
exports.CategoryFood = CategoryFood;
var CustomerCategory = {
  ALL: "Tất cả",
  BIRTHDAY_IN_MONTH: "Sinh nhật trong tháng",
  OFFLINE: "Không hoạt động",
  FEMALE: "Nữ",
  BADGE: "Cấp bậc"
};
exports.CustomerCategory = CustomerCategory;
var CoverType = {
  HARD: "Bìa cứng",
  SOFT: "Bìa mềm"
};
exports.CoverType = CoverType;
var OrderStatus = {
  PENDING: "Đang xác nhận",
  COMPLETE: "Đã giao",
  CANCEL: "Huỷ",
  DELIVERING: "Đang vận chuyển"
};
exports.OrderStatus = OrderStatus;