var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("div", { staticClass: "filter-container" }, [
        _c(
          "div",
          { staticClass: "filter-item" },
          [
            _c(
              "el-button",
              {
                attrs: { type: "primary", icon: "el-icon-plus" },
                on: { click: _vm.handleCreate },
              },
              [_vm._v("Thêm mới")]
            ),
          ],
          1
        ),
      ]),
      _c(
        "el-table",
        { attrs: { data: _vm.data, border: "", stripe: "" } },
        [
          _c("el-table-column", {
            attrs: { label: "Tên phần thưởng", prop: "name" },
          }),
          _c("el-table-column", {
            attrs: { label: "Mô tả thêm", prop: "description" },
          }),
          _c("el-table-column", {
            attrs: { label: "Tỷ lệ trúng" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [_vm._v(" " + _vm._s(row.ratio) + " % ")]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Số lượng" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      " " + _vm._s(_vm._f("toThousandFilter")(row.total)) + " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Đã trúng" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _vm._v(
                      " " + _vm._s(_vm._f("toThousandFilter")(row.give)) + " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Hành động" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-tooltip",
                      { attrs: { content: "Cập nhật", placement: "top" } },
                      [
                        _c("el-button", {
                          attrs: {
                            size: "mini",
                            type: "primary",
                            icon: "el-icon-edit",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.handleUpdate(row)
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("PieceModal", {
        ref: "piece-modal",
        on: { "submit:success": _vm.fetchData },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }